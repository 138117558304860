<template>
  <div :style="cssProps">
    <div class="top">
      <router-link :to="`/blog/fr/${currentNews.news}`">Version française</router-link>
      |
      <router-link :to="`/blog/en/${currentNews.news}`">English version</router-link>
    </div>
    <div id="modal-img" class="modal">
      <img class="modal-img-content" id="modal-img-01" />
    </div>
    <div id="toc-blog"></div>
    <vue-markdown
      class="blogContent"
      :source="blog.content"
      :toc="toc"
      :toc-anchor-link="false"
      toc-id="toc-blog"
      v-on:rendered="allRight"
      v-on:toc-rendered="tocAllRight"
      :toc-first-level="2"
      :toc-last-level="2"
    ></vue-markdown>
    <div class="bottom">
      {{ footer }}
    </div>
  </div>
</template>

<script>
import blogs from "../blogs.js";
import VueMarkdown from "vue-markdown";
import moment from "moment";

export default {
  name: "BlogContent",
  props: {},
  data: () => ({ toc: true, toc_content: "" }),
  computed: {
    isKiteDoc: function() {
      // it is a kite doc blog if the id (or the md file) starts with "kite_"
      let id = this.blog.id.split("/")[1];
      if (id.substr(0, 5) === "kite_") {
        return true;
      } else {
        return false;
      }
    },
    currentNews: function() {
      const url = window.location.href.split("/");
      const language = url[url.length - 2];
      const news = url[url.length - 1];
      return {
        language: language,
        news: news
      };
    },
    blog: function() {
      const { uuid } = this.$route.params;
      return blogs.filter(b => b.id === uuid).pop();
    },
    date: function() {
      moment.locale(this.blog.language);
      let d = moment(this.blog.date, "YYYY-MM-DD");
      return d.format("LL");
    },
    footer: function() {
      return this.date + ", " + this.blog.author;
    },
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.themes.light.primary,
        "--secondary-color": this.$vuetify.theme.themes.light.secondary
      };
    }
  },
  components: {
    VueMarkdown
  },
  mounted: function() {
    // remove footnote links
    this.$el.querySelectorAll(".footnote-backref").forEach(e => {
      e.remove();
    });

    // add link direction to new tab
    this.$el.querySelectorAll("div.blogContent > p > a").forEach(e => {
      e.setAttribute("target", "_blank");
    });
    // remove internal links as it doesn't work
    this.$el.querySelectorAll("a").forEach(e => {
      let id = e.getAttribute("id");
      if (id !== null) {
        let text = e.text;
        let parent = e.parentNode;
        e.remove();
        parent.textContent = text;
      }
    });

    this.createModalImages();
    this.updateTocContent();
    this.fixScrollAnchor();
  },
  methods: {
    waitForElm(selector) {
      // wait for element function
      return new Promise(resolve => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
            observer.disconnect();
            resolve(document.querySelector(selector));
          }
        });

        // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      });
    },
    async createModalImages() {
      // modal for images
      const elm = await this.waitForElm(".blogContent");
      console.log(this.$el.querySelectorAll("div.blogContent img").length);
      this.$el.querySelectorAll("div.blogContent img").forEach(e => {
        let modal = document.getElementById("modal-img");
        var modalImg = document.getElementById("modal-img-01");
        let alt = e.getAttribute("alt");
        var img = document.querySelectorAll('[alt="' + alt + '"]')[0];

        img.onclick = function() {
          modal.style.display = "block";
          modalImg.src = this.src;
        };
      });

      let modal = document.getElementById("modal-img");
      modal.onclick = function() {
        modal.style.display = "none";
      };
    },
    async fixScrollAnchor() {
      // update toc links in order to correctly move to the anchor
      const elm = await this.waitForElm("#toc-blog-content");
      this.$el.querySelectorAll("div #toc-blog-content a").forEach(e => {
        let hash = e.getAttribute("href");
        let id = e.getAttribute("id");
        if (id !== "back" && !!hash) {
          // don't change back links or if hash is null
          e.removeAttribute("href");
          e.onclick = function() {
            location.hash = hash;
            window.scrollBy({
              top: -70
            });
          };
        }
      });
    },
    updateTocContent() {
      // update toc content
      let toc = document.getElementById("toc-blog");
      if (!!toc) {
        toc.innerHTML = this.toc_content;
      }
    },
    allRight(htmlStr) {
      console.log("markdown is parsed !");
      this.createModalImages();
      this.updateTocContent();
      this.fixScrollAnchor();
    },
    tocAllRight(tocHtmlStr) {
      // use id=back for identifying added links in order to avoid removing it
      let kite_doc_link =
        "<br/><b><a id='back' href='#/blog/fr/kite_guides_step_by_step'>&#62; Retour aux guides</a></b>";
      if (!this.isKiteDoc) {
        kite_doc_link = "<br/><a id='back' href='#/blog_full_list'>Retour aux actualités</a>";
      }
      this.toc_content = "<h3>Menu</h3><div id='toc-blog-content'>" + tocHtmlStr + kite_doc_link + "</div>";
      console.log("toc is ready !");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.disabled-link {
  color: #ccc;
}

#toc-blog {
  position: fixed;
  z-index: 1;
  left: 10px;
  top: 80px;
  width: 250px;
  margin: 25px px 20px 0px;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: auto;
  padding: 1px;
}

#toc-blog-content {
  text-align: left;
}

@media (max-width: 780px) {
  #toc-blog {
    display: none;
  }
}

#toc-blog-content {
  text-align: left;
}

#toc-blog li {
  cursor: pointer;
  color: var(--v-primary);
}

#toc-blog a {
  text-decoration: none;
  font-family: "Open Sans";
  font-size: 14px;
}

#toc-blog a:hover {
  color: #85c287;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(129, 129, 129); /* Fallback color */
  background-color: rgba(112, 112, 112, 0.9); /* Black w/ opacity */
  cursor: pointer;
}

/* Modal Content (image) */
.modal-img-content {
  margin: auto;
  display: block;
  max-width: 80%;
}

/* Add Animation */
.modal-img-content {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 780px) {
  .modal-img-content {
    max-width: 1000px;
  }
}

.top {
  padding-top: 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: grey;
  padding: 0 15px 0 15px;
}

.blogContent {
  margin: 40px;
  min-height: 100vh;
  max-width: 800px;
  text-align: justify;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px 0 15px;
}

.blogContent > h1 {
  color: var(--secondary-color);
  text-align: left;
}

.blogContent > h2 {
  color: var(--secondary-color);
}

.blogContent > p > img,
.blogContent > ul > li > img,
.blogContent > ul > li > ul > li > img {
  max-width: 600px;
  background-color: white;
  display: block;
  margin: 0 auto;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  -moz-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  -ms-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  -o-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
  cursor: pointer;
  transition: 0.3s;
}

.blogContent > p > img:hover {
  opacity: 0.7;
}

.blogContent > p > em {
  text-align: center;
  margin: 0 auto;
  display: block;
}
.blogContent > iframe {
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 1em;
}
.blogContent > ul {
  padding-bottom: 15px;
}

table {
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
}

th {
  background-color: var(--secondary-color);
  color: white;
}

.footnotes-sep {
  border-width: 0px;
}

.blogContent > pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
  margin-bottom: 15px;
}

.theme--light.v-application code {
  background-color: rgb(0 0 0 / 0%) !important;
}

.footnotes {
  text-align: left;
}

ul > li > p {
  font-weight: bold;
  margin-top: 15px;
}

.v-application ul > li > p {
  margin-bottom: 0px !important;
}

.blogContent a {
  text-decoration: none;
}

.blogContent a:hover {
  color: #85c287;
}
</style>
